<template>
  <div class="large-page">
    <breadcrumbs>
      <div class="d-flex flex-wrap flex-grow-1 justify-end align-center mt-3 mt-md-0 ml-md-4">
        <!-- search input -->
        <v-text-field
          v-model.trim="searchQuery"
          dark
          dense
          outlined
          hide-details
          label="Search Users"
          class="mr-sm-3 mr-md-6"
          append-icon="search"
          @click:append="handlePagination(1)"
          @keypress.enter="handlePagination(1)"
        />

        <div class="mt-3 mt-sm-0 d-flex">
          <v-btn
            depressed
            class="mr-3"
            color="white"
            @click="$router.push({ name: 'Team/Members/Create' })"
          >
            <v-icon color="secondary" left>
              add
            </v-icon>

            Add User
          </v-btn>

          <v-btn
            depressed
            color="white"
            @click="fetchItems"
          >
            <v-icon color="secondary" left>
              refresh
            </v-icon>

            Refresh
          </v-btn>
        </div>
      </div>
    </breadcrumbs>

    <template v-if="apiResponse">
      <v-data-table
        :headers="tableHeaders"
        :items="apiResponse.data"
        :server-items-length="apiResponse.total"
        class="mt-4 box-shadow-soft team-members-data-table"
        @update:page="handlePagination"
        @update:items-per-page="handlePerPage"
        mobile-breakpoint="100"
      >
        <template v-slot:item.balance="{ item }">
          {{ item.balance / constants.conversionRate }}
        </template>

        <template v-slot:item.country="{ item }">
          <v-img
            v-if="item.country"
            height="13"
            width="20"
            :src="proxyUrl(`https://flagcdn.com/w20/${item.country.toLowerCase()}.png`, true)"
          ></v-img>
        </template>

        <template v-slot:item.last_active_at="{ item }">
          <span
            class="cursor-pointer"
            :title="item.last_active_at"
          >
            {{ item.last_active_at_relative }}
          </span>
        </template>

        <template v-slot:item.is_active="{ item }">
          {{ item.is_active ? "Active" : "Inactive" }}
        </template>

        <template v-slot:item.action="{ item }">
          <div class="d-flex justify-end">
            <v-btn
              small
              depressed
              class="mr-3"
              color="primary"
              @click="$router.push({ name: 'Team/Members/Edit', params: { userId: item.id } })"
            >
              Edit
            </v-btn>

            <v-btn
              small
              depressed
              color="primary"
              @click="loginAsTheUser(item.id)"
              :disabled="(item.valid_to && !dayjs().isBefore(dayjs(item.valid_to))) || !item.is_active"
            >
              Login
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
// Import child components
const Breadcrumbs = () => import(/* webpackChunkName: "breadcrumbs" */ "@/components/common/Breadcrumbs.vue")

// Import local storage helper functions
import { localStorageManager, API_DATA } from "@/helpers/localStorageManager.js"

export default {
  name: "TeamIndex",

  components: {
    Breadcrumbs
  },

  data: () => ({
    tableHeaders: [
      // {
      //   text: "ID",
      //   value: "id",
      //   sortable: false
      // },
      {
        text: "Name",
        value: "name",
        sortable: false
      },
      {
        text: "Email ID",
        value: "email",
        sortable: false
      },
      {
        text: "Mobile No.",
        value: "phone",
        sortable: false
      },
      {
        text: "Country",
        value: "country",
        sortable: false
      },
      {
        text: "Role",
        value: "role",
        sortable: false
      },
      {
        text: "Balance",
        value: "balance",
        sortable: false
      },
      {
        text: "Last Active At",
        value: "last_active_at",
        sortable: false
      },
      {
        text: "IP Address",
        value: "last_active_ip_address",
        sortable: false
      },
      {
        text: "Status",
        value: "is_active",
        sortable: false
      },
      {
        text: "Action",
        value: "action",
        sortable: false
      },
    ]
  }),

  computed: {
    query() {
      return this.$store.getters["team/query"]
    },

    apiResponse() {
      return this.$store.getters["team/apiResponse"]
    },

    // Use getter and setter to link the value with vuex store
    searchQuery: {
      get() {
        return this.query.search
      },

      set(value) {
        // update the query term
        this.$store.dispatch("team/updateQuery", { key: 'search', value })

        // reset the page number to 1
        this.$store.dispatch("team/updateQuery", { key: 'page', value: 1 })
      }
    }
  },

  methods: {
    handlePagination(value) {
      this.$store.dispatch("team/updateQuery", { key: 'page', value })

      this.fetchItems()
    },

    handlePerPage(value) {
      this.$store.dispatch("team/perPage", { key: 'page', value })

      this.fetchItems()
    },

    fetchItems() {
      this.$store.dispatch("team/fetchItems")
    },

    /**
     * Login as the user instead of current team manager
     *
     * @param {Number} id
     * @returns {void}
     */
    async loginAsTheUser(id) {
      // Show the loader
      const loaderId = Symbol()

      this.$store.dispatch("loaders/add", loaderId)

      try {
        // Make the network request to the auth API
        const response = await axios({
          url: "/api/team/login-as-user/" + id,
          method: "POST"
        })

        // Get the current team manager's access token
        const currentAccessToken = getAccessToken()

        // Clear the localStorage state
        window.localStorage.clear()
        window.sessionStorage.clear()

        // Store the current token as the fallback token
        localStorageManager({
          name: API_DATA,
          key: "fallback_token",
          value: currentAccessToken
        })

        // Save the logged in user's token for new session
        localStorageManager({
          name: API_DATA,
          key: "access_token",
          value: response.data.token
        })

        // Save the URL to return back to
        localStorageManager({
          name: API_DATA,
          key: "redirect_after_logout",
          value: "/team/members"
        })

        // Now redirect to the dashboard
        window.location.href = "/dashboard"
      } catch (error) {
        // Log the error
        console.error(error)

        this.$store.dispatch("toasts/add", { text: "An error occurred, please try again." })
      } finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
      }
    }
  },

  mounted() {
    // only reload the data on mounted if it's null
    if (this.apiResponse === null) {
      this.fetchItems()
    }
  }
}
</script>

<style lang="stylus">
.team-members-data-table thead tr th
  white-space nowrap !important
</style>
